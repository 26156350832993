import React from 'react';

export default () => {
  return (
    <div>
      <div className="skeleton-post">
        <div className="skeleton-title" />
        <div className="skeleton-date" />
        <div className="skeleton-text">
          <div className="skeleton-text-line" />
          <div className="skeleton-text-line" />
          <div className="skeleton-text-line" />
          <div className="skeleton-text-line" />
        </div>
        <ul className="skeleton-tags">
          <div className="skeleton-tag" />
          <div className="skeleton-tag" />
        </ul>
      </div>
      <hr className="horizontal-ruler thin" />
      <div className="skeleton-post">
        <div className="skeleton-title" />
        <div className="skeleton-date" />
        <div className="skeleton-text">
          <div className="skeleton-text-line" />
          <div className="skeleton-text-line" />
          <div className="skeleton-text-line" />
          <div className="skeleton-text-line" />
        </div>
        <ul className="skeleton-tags">
          <div className="skeleton-tag" />
          <div className="skeleton-tag" />
        </ul>
      </div>
      <hr className="horizontal-ruler thin" />
      <div className="skeleton-post">
        <div className="skeleton-title" />
        <div className="skeleton-date" />
        <div className="skeleton-text">
          <div className="skeleton-text-line" />
          <div className="skeleton-text-line" />
          <div className="skeleton-text-line" />
          <div className="skeleton-text-line" />
        </div>
        <ul className="skeleton-tags">
          <div className="skeleton-tag" />
          <div className="skeleton-tag" />
        </ul>
      </div>
    </div>
  );
};
