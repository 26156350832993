import React, { Component } from 'react'
import Post from '../components/common/Post'
import MultiplePostsSkeleton from '../components/common/MultiplePostsSkeleton'
import Layout from '../components/layout/Layout'
import PaginationLinks from '../components/common/PaginatonLinks'
import { Link, graphql } from 'gatsby'
import { slugify } from '../util/utilityFunctions'

class postList extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }
  render() {
    const { numberOfPages, currentPage, limit } = this.props.pageContext

    let allPostsContent
    if (this.state.loading) {
      allPostsContent = <MultiplePostsSkeleton />
    } else {
      const posts = this.props.data.allMarkdownRemark.edges
      let rulerBool
      let index = 0
      allPostsContent = posts.map(({ node }) => {
        index++
        rulerBool = index !== limit
        return (
          <Post
            key={node.id}
            title={node.frontmatter.title}
            slug={node.fields.slug}
            body={node.excerpt}
            createdAt={node.frontmatter.date}
            tags={node.frontmatter.tags}
            ruler={rulerBool}
          />
        )
      })
    }
    let headContent
    if (currentPage === 1) {
      headContent = (
        <div>
          <h1 className="section-title">Blog</h1>
          <p className="subtitle">
            Here, you'll find technical blog posts that I write, thoughts and
            general updates on what I'm up to
          </p>
        </div>
      )
    } else {
      headContent = (
        <div>
          <h1 className="section-title">{`Page ${currentPage}`}</h1>
        </div>
      )
    }
    if (this.props.pageContext)
      return (
        <Layout pageTitle="Blog">
          {headContent}
          <div className="text-center">
            <ul className="post-tags">
              {this.props.pageContext.tags.sort().map(tag => (
                <Link key={tag} className="tag" to={`/tag/${slugify(tag)}`}>
                  {tag.toUpperCase()}
                </Link>
              ))}
            </ul>
          </div>
          <hr className="horizontal-ruler" />
          {allPostsContent}
          <PaginationLinks
            currentPage={currentPage}
            numberOfPages={numberOfPages}
          />
        </Layout>
      )
  }
}

export const pageQuery = graphql`
  query postListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM Do YYYY")
            tags
          }
          fields {
            slug
          }
          excerpt(pruneLength: 400)
        }
      }
    }
  }
`

export default postList
