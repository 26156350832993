import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const PaginationLinks = ({ currentPage, numberOfPages }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numberOfPages
  const previousPage =
    currentPage - 1 === 1
      ? '/blog'
      : '/blog/page/' + (currentPage - 1).toString()
  const nextPage = '/blog/page/' + (currentPage + 1).toString()

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center">
        {isFirst ? (
          <li className="page-item disabled">
            <Link className="page-link" to="/blog">
              Previous
            </Link>
          </li>
        ) : (
          <li className="page-item">
            <Link className="page-link" to={previousPage}>
              Previous
            </Link>
          </li>
        )}
        {Array.from({ length: numberOfPages }, (_, i) =>
          currentPage === i + 1 ? (
            <li className="page-item active" key={`page-number${i + 1}`}>
              <Link
                className="page-link"
                to={`/blog${i === 0 ? '' : '/page/' + (i + 1)}`}
              >
                {i + 1}
              </Link>
            </li>
          ) : (
            <li className="page-item" key={`page-number${i + 1}`}>
              <Link
                className="page-link"
                to={`/blog${i === 0 ? '' : '/page/' + (i + 1)}`}
              >
                {i + 1}
              </Link>
            </li>
          )
        )}
        {isLast ? (
          <li className="page-item disabled">
            <Link className="page-link" to={nextPage}>
              Next
            </Link>
          </li>
        ) : (
          <li className="page-item">
            <Link className="page-link" to={nextPage}>
              Next
            </Link>
          </li>
        )}
      </ul>
    </nav>
  )
}

PaginationLinks.propTypes = {
  currentPage: PropTypes.number.isRequired,
  numberOfPages: PropTypes.number.isRequired,
}

export default PaginationLinks
